const Texts = (page, type) => {
    const Text = {
        about: {
            about: 'НАША ГЛАВНАЯ ЦЕННОСТЬ — ЭТО ДОЛГОСРОЧНЫЕ И ДОВЕРИТЕЛЬНЫЕ ОТНОШЕНИЯ С КЛИЕНТАМИ И ПАРТНЕРАМИ, В РЕЗУЛЬТАТЕ КОТОРЫХ ЛЕЖИТ ГЛУБОКОЕ ВЗАИМНОЕ ПОНИМАНИЕ СПЕЦИФИКИ, ЗАДАЧ И ПЕРСПЕКТИВ РАЗВИТИЯ БИЗНЕСА.',
            exp: 'БОЛЕЕ 10 ЛЕТ ОПЫТА РАБОТЫ НА РЫНКЕ IT КАЗАХСТАНА',
            office: 'Офис в Нур-Султане (с наличием техподдержки)',
            qual: 'Более 20 сертифицированных специалистов в различных областях IT',
            projects: 'Более 15-ти крупных, успешно внедренных IT проектов',
            partners: 'Широкая партнерская сеть на территории РК и за рубежом',
        },
        solutions: {
            h1: 'Поставка и внедрение специализированных программно-аппаратных систем для вашей сети',
            p1: 'Использование ИТ напрямую влияет на создание конкурентных преимуществ для устойчивого развития предприятия, создание новых продуктов и услуг, выход на новые рынки, повышение прозрачности финансово-хозяйственной деятельности, совершенствование корпоративного управления, повышение эффективности профильной деятельности корпораций нефтегазового сектора. С помощью наших специалистов Вы быстро подберете оптимальное оборудование для вашей инфраструктуры.',
            h2: 'Поставка и внедрение систем хранения данных',
            p2: 'Наши специалисты произведут расчет необходимых мощностей под Ваши требования и подберут оборудование любого типа и под объект любой величины. Помимо заказа, мы производим полный цикл работ по внедрению СХД, с помощью наших специалистов.',
            h3: 'Поставка и внедрение серверов',
            p3: 'Подбор и продажа для заказчика серверного оборудования под любые типы задач и для объекта любой величины. Результат – полностью готовая к эксплуатации серверная инфраструктура, настроенная с учетом всех требований заказчика.',
            h4: 'Система управления сетью (NMS)',
            p4: 'Внедрив систему управления сетью (NMS), вы сможете: оптимизировать использование и загрузку вашей сети; минимизировать влияние возможных отказов оборудования на обслуживание ваших клиентов; поддерживать и улучшать сеть и ее терминалы для решения еще не возникших проблем или, по крайней мере, еще до того, как у пользователя возникнут проблемы.',
            h5: 'Защита сети и анализ вредоносного трафика',
            p5: 'Данное решение ориентировано в большей мере на корпоративный/финансовый/банковский сектора, на организации, которым критично защитить корпоративную информационную систему. Архитектурно система представляет собой программно-аппаратный комплекс, в состав которого входит: система предотвращения вторжений; система, выполняющая анализ поведения трафика; система, выполняющая динамический и статический анализ исполняемого кода.',
            h6: 'Видеонаблюдение',
            p6: 'Решения от ведущих мировых производителей в области IP видеонаблюдения для различных объектов и государственных предприятий, аэропортов и других больших производственных объектов, в том числе имеющих несколько разнесенных географически площадок.',
            h7: 'Контроль доступа',
            p7: 'Решения для систем контроля доступа и интегрированных систем управления безопасностью масштаба предприятия там, где требуется обеспечение высокой степени безопасности при большом количестве транспорта и персонала. Для таких предприятий, как большие аэропорты с постоянно меняющимся составом держателей карт доступа.',
        },
        projects: {
            p1: 'АО "Жилищный строительный сберегательный Банк Казахстана"',
            p2: 'РГУ "Комитет Национальной Безопасности" РК',
            p3: 'РГУ "Национальный Банк"',
            p4: 'АО "Центр электронной коммерции"',
            p5: 'ТОО"ALMANIT"',
            p6: 'ГУ Агенство Республики Казахстан по делам государственой службы"',
            p7: 'АОО"Назарбаев Интеллектуальные школы"',
            p8: 'РГУ "Министерство Финансов РК"',
            p9: 'АО "Аграрная кредитная корпорация',
            p10: 'РГП "Казаэронавигация"',
            t1: 'Поставка Аппаратно- программного комплекса',
            t2: 'Поставка Аппаратно- программного комплекса и годовые лицензии',
            t3: 'Предоставление услуг по технической поддержке программно аппаратного комплекса',
            t4: 'Услуги по технической поддержке оборудования на проекте АИИС ЭГЗ',
            t5: 'Услуги по предоставлению сервиса технической поддержки программного обеспечения и оборудования Cisco',
            t6: 'Поставка коммутаторов',
            t7: 'Поставка ленточной библиотеки с накопителями',
            t8: 'Поставка телекоммуникационного оборудования',
            t9: 'Услуги по предоставлению вычислительной мощности',
            t10: 'Система видеонаблюдения\n' +
                'Лицензии для резервного копирования виртуальных систем\n' +
                'Услуги по техническому (сервисному) обслуживанию и ремонту охранного/досмотрового оборудования\n' +
                'Система контроля и управления доступом'
        },
        homepage: {
            title: 'МЫ поставляем ИННОВАЦИОННЫе ПРОГРАММНЫе И АППАРАТНЫе РЕШЕНИя В ОБЛАСТИ IT',
            services: 'Наши услуги',
            h1: 'СИСТЕМНАЯ ИНТЕГРАЦИЯ, ПРОЕКТИРОВАНИЕ И СОЗДАНИЕ КОРПОРАТИВНЫХ СЕТЕЙ',
            h2: 'СОЗДАНИЕ ПОЛНОМАСШТАБНЫХ ИНФОРМАЦИОННЫХ СИСТЕМ УРОВНЯ ПРЕДПРИЯТИЯ',
            h3: 'РЕШЕНИЯ ПО БЕЗОПАСНОСТи',
            h4: 'ИНФРАСТРУКТУРНЫЕ РЕШЕНИЯ',
            h5: 'Техническая поддержка и сервисные услуги',
            h6: 'Решения в области центров обработки данных и СХД',
            advantage: 'Преимущества',
            adv1: 'Специализированные решения',
            adv2: 'Весь товар сертифицирован',
            adv3: 'Оперативная доставка заказчику',
            partners: 'наши партнеры',
        }
    };
    return Text[page][type];
};

export default Texts;
